$(document).ready(function() {
    $('#cookie-akkoord').click(function(e) {
        e.preventDefault();
        $.cookie('cookie-bar', 'true', {expires: 1000, path: '/'});
        $('.cookie-bar').remove();

    });
    $('#cookie-close').click(function(e) {
        e.preventDefault();
        $('.cookie-bar').remove();
    });
});