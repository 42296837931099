var scrollP = 0.1;
var $grid;
$(document).ready(function() {
    $.ajaxSetup({cache: false});

    if ($('.top-carrousel ul li').length > 1) {
        $('.top-carrousel ul').bxSlider({
            mode: 'fade',
            pager: false,
            controls: true,
            auto: true,
            //nextSelector: $('.pageheader .next_btn'),
            //prevSelector: $('.pageheader .prev_btn'),
            nextText: '',
            prevText: '',
            touchEnabled: true
        });
    }

    $("#contact-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('d-none');
            $('.alert-success', form).addClass('d-none');
            $.post(window.base_url +"engine/contact.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('d-none');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('d-none');
                }
            }, 'json');
        }
    });

    /*
    Gmap3 functionality
    Needed for blocks: 130, 131
    */
    $('.gmap').each(function( index ) {
        var gmap_address = $(this).data('address');
        $(this).gmap3({
            address: gmap_address,
            zoom: 12,
            mapTypeId : google.maps.MapTypeId.ROADMAP
        })
            .marker({
                address: gmap_address,
                icon: 'https://maps.google.com/mapfiles/marker_green.png'

            }).on({
            click: function(marker, event, data) {
                window.open("https://maps.google.com/?daddr="+gmap_address);
            }
        })
    });

    $('body').fitVids();
});

$(document).scroll(function() {
    var windowTop = $(window).scrollTop(),
        windowWidth = $(window).width(),
        windowHeight = $(window).height(),
        visualHeight = $('#header').height(),
        footerTop = $('footer').offset().top;

    if (windowTop > visualHeight * scrollP ) {
        $('body').addClass('in-scroll')
    }
    //console.log((windowTop > (windowHeight * 0.2)) + ' -- ' + ( (footerTop - windowHeight) < windowTop ) + ' = ' + footerTop + ' - ' + windowHeight + ' - ' + windowTop + ' - ');
    //console.log((windowTop) + ' -- ' + visualHeight);
    if ( (windowTop < visualHeight * scrollP ) || (windowTop == 0) ) {
        $('body').removeClass('in-scroll')
    }

});



function equalHeights(element, container){
    if (typeof container === 'undefined') {
        c = $(element).parent();
    } else {
        c = $(container);
    }

    var highestBox = 0;
    c.find(element).each(function(){
        highestBox = Math.max(highestBox, $(this).outerHeight());
    });
    //console.log(highestBox);
    c.find(element).outerHeight(highestBox);

}
